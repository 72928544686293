import React, { useCallback, useEffect, useState } from 'react';
import { breakpoints } from '@naf/theme';
import { AdvancedImage, lazyload, placeholder } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { ImageBox } from './styles';
import { CloudinaryImageType } from '../../../../../../types/blockTypes';

export const HeroPictureImage = ({ image }: { image: CloudinaryImageType }) => {
  const publicId = image?.public_id || image?.publicId;
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();

  const [mainImage, setMainImage] = useState(
    (publicId &&
      cld
        .image(publicId)
        .resize(fill().width(539).height(464).gravity(autoGravity()))
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const setResponsiveImage = useCallback(() => {
    if (publicId && typeof window !== 'undefined') {
      const width = window.innerWidth <= parseInt(breakpoints.m, 10) ? Math.round(window.innerWidth) : 539;
      const height = window.innerWidth <= parseInt(breakpoints.m, 10) ? Math.round(width * 0.86) : 464;
      setMainImage(
        cld
          .image(publicId)
          .resize(fill().width(width).height(height).gravity(autoGravity()))
          .delivery(dpr(devicePixelRatio))
          .quality('auto:best')
          .format('auto'),
      );
    }
  }, [cld, devicePixelRatio, publicId]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  return (
    mainImage && (
      <ImageBox>
        <AdvancedImage
          cldImg={mainImage}
          plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), placeholder({ mode: 'blur' })]}
          alt={image.alt || image.altText || image.caption}
        />
      </ImageBox>
    )
  );
};
