import React from 'react';
import { AdvancedImage, lazyload, placeholder } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { IllustrationBox } from './styles';
import { CloudinaryImageType } from '../../../../../../types/blockTypes';

export const HeroIllustrationImage = ({ image }: { image: CloudinaryImageType }) => {
  const publicId = image?.public_id || image?.publicId;
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const mainImage = cld.image(publicId).delivery(dpr(devicePixelRatio));
  return (
    <IllustrationBox>
      <AdvancedImage
        cldImg={mainImage}
        plugins={[lazyload({ threshold: 0.25 }), placeholder({ mode: 'blur' })]}
        alt={image.alt || image.altText || image.caption}
      />
    </IllustrationBox>
  );
};
