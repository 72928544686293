import React, { useEffect, useState } from 'react';
import { Grid } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { BlockContainer } from '../BlockContainer';
import { CenterBlock, HeroWrapper2 } from './styles';
import BreadCrumb from '../../../breadcrumb/BreadCrumb';
import { BreadCrumbContainer } from './BreadCrumbContainer';
import { HeroWithTitleAndIngressType } from '../../../../../../types/heroes/heroWithTitleAndIngressType';

interface FrontPageHeroType extends HeroWithTitleAndIngressType {
  isFrontPage?: boolean;
}

export const TitleAndIngressHero = ({
  hero: { header, ingress, isFrontPage },
  breadCrumbs,
}: {
  hero: FrontPageHeroType;
  breadCrumbs?: BreadcrumbData[];
}) => {
  // Do not simplify this - it caused the webapp to break in production
  const [shouldShowBreadcrumbs, setShouldShowBreadcrumbs] = useState(false);
  useEffect(() => {
    setShouldShowBreadcrumbs(breadCrumbs?.length > 1);
  }, [breadCrumbs]);
  // Do not simplify this - end
  return (
    <>
      {shouldShowBreadcrumbs && (
        <BreadCrumbContainer>
          <BreadCrumb />
        </BreadCrumbContainer>
      )}
      <BlockContainer $isFrontPage={isFrontPage} $hasBreadCrumbs={shouldShowBreadcrumbs}>
        <CenterBlock>
          <Grid>
            <HeroWrapper2 s="12" m="6" l="6" xl="6">
              {header && (
                <Text tag="h1" variant={TextVariant.Header1}>
                  {header}
                </Text>
              )}
              {ingress && (
                <Text tag="p" variant={TextVariant.Ingress}>
                  {ingress}
                </Text>
              )}
            </HeroWrapper2>
          </Grid>
        </CenterBlock>
      </BlockContainer>
    </>
  );
};
