import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { Text, TextVariant } from '@naf/text';
import { ButtonLink } from '@naf/button-link';
import { Label } from '@naf/label';
import { spacing } from '@naf/theme';
import styled from 'styled-components';
import { BlockContainer } from '../BlockContainer';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { CenterBlock, HeroWrapper, TextBox, StyledButton } from './styles';
import BreadCrumb from '../../../breadcrumb/BreadCrumb';
import { BreadCrumbContainer } from './BreadCrumbContainer';
import { HeroPictureImage } from './HeroPictureImage';
import { HeroIllustrationImage } from './HeroIllustrationImage';
import { NafLogo } from '../QnaFeedBlock/NafLogo';
import { getLabelVariant } from '../../../../lib/getLabelVariant';
import { HeroWithTitleIngressSmallImageAndCtaType } from '../../../../../../types/heroes/heroWithTitleIngressSmallImageAndCta';
import { HeroType } from '../../../../../../types/frontPageType';

interface HeroPropsType extends HeroWithTitleIngressSmallImageAndCtaType {
  isFrontPage?: boolean;
}

interface OldHeroPropsType extends HeroType {
  heroVariant: 'heroWithTitleIngressSmallImageAndCta';
  isFrontPage?: boolean;
}

const HeaderNafLogoWrapper = styled.div`
  display: block;
  margin-bottom: ${spacing.space16};
`;

const LabelWrapper = styled.div`
  display: block;
  margin-top: ${spacing.space16};
`;

export const TitleAndIngessImageCtaHero = ({
  breadCrumbs,
  hero: { header, ctaInternalLink, ctaExternalLink, image, isFrontPage, ingress, illustration, includeNafLogo, label },
}: {
  hero: HeroPropsType | OldHeroPropsType;
  breadCrumbs?: BreadcrumbData[];
}) => {
  const history = useHistory();
  const ctaUrl = useDocumentUrlWithNode(ctaInternalLink?.referenceComponent);

  // Do not simplify this - it caused the webapp to break in production
  const [shouldShowBreadcrumbs, setShouldShowBreadcrumbs] = useState(false);
  useEffect(() => {
    setShouldShowBreadcrumbs(breadCrumbs?.length > 1);
  }, [breadCrumbs]);
  // Do not simplify this - end

  return (
    <>
      {shouldShowBreadcrumbs && (
        <BreadCrumbContainer>
          <BreadCrumb />
        </BreadCrumbContainer>
      )}
      <BlockContainer $isFrontPage={isFrontPage} $hasBreadCrumbs={shouldShowBreadcrumbs}>
        <CenterBlock>
          <HeroWrapper>
            <TextBox>
              {includeNafLogo && (
                <HeaderNafLogoWrapper>
                  <NafLogo width="48px" height="48px" />
                </HeaderNafLogoWrapper>
              )}
              {header && (
                <Text tag="h1" variant={TextVariant.Header1}>
                  {header}
                </Text>
              )}
              {ingress && (
                <Text tag="p" variant={TextVariant.Ingress}>
                  {ingress}
                </Text>
              )}
              {label && (
                <LabelWrapper>
                  <Label text={label.title} variant={getLabelVariant(label.colorVariant)} />
                </LabelWrapper>
              )}
              {ctaInternalLink && (
                <StyledButton
                  size="large"
                  variant="secondary"
                  onClickFn={() => {
                    if (ctaUrl) {
                      history.push(ctaUrl);
                    }
                  }}
                  text={ctaInternalLink.text}
                />
              )}
              {ctaExternalLink && (
                <StyledButton
                  as={ButtonLink}
                  variant="secondary"
                  href={ctaExternalLink.link}
                  target={ctaExternalLink.blank ? '_blank' : '_self'}
                  text={ctaExternalLink.text}
                />
              )}
            </TextBox>
            {illustration ? <HeroIllustrationImage image={image} /> : <HeroPictureImage image={image} />}
          </HeroWrapper>
        </CenterBlock>
      </BlockContainer>
    </>
  );
};
