import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const BreadCrumbContainer = styled.div`
  max-width: ${breakpoints.xl};
  padding: ${spacing.space56} 0 ${spacing.space48} 0;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.l}) {
    padding: 0 ${spacing.space24};
  }
`;
